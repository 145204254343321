import { Box, Divider, Typography, styled } from "@mui/material";
import {
  HelpOutline as HelpOutlineIcon,
  PlayArrow as PlayArrowIcon,
  Payment as PaymentIcon,
  CalendarMonth as CalendarMonthIcon,
  Person as PersonIcon,
} from "@mui/icons-material";
import { PreviewVideo } from "amazon-chime-sdk-component-library-react";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";
import Button from "../../../buttons/Button";
import Tooltip from "../../../Tooltip";
import { ChimeVideoCall } from "../../ChimeVideoCall.types";
import { ICallContext } from "../../context/Call";
import CallContext from "../../context/CallContext";
import { IWithChildren } from "../../../../../baseInterfaces";
import CashTransaction from "../../../../../data-model/types/CashTransaction";
import AuthContext from "../../../../providers/auth/AuthContext";
import VideoInputDeviceSelection from "./VideoInputDeviceSelection";
import AudioInputDeviceSelection from "./AudioInputDeviceSelection";
import ActivityBar from "./ActivityBar";
import moment, { Moment } from "moment";
import { parseISO } from "date-fns/parseISO";
import { differenceInMinutes } from "date-fns";

const PREFIX = "AudioVisualSettingsContainer";

const classes = {
  buttonContainer: `${PREFIX}-buttonContainer`,
  content: `${PREFIX}-content`,
  headSection: `${PREFIX}-headSection`,
  helpContent: `${PREFIX}-helpContent`,
  helpText: `${PREFIX}-helpText`,
  joinButton: `${PREFIX}-joinButton`,
  payMsgContainer: `${PREFIX}-payMsgContainer`,
  previewVideo: `${PREFIX}-previewVideo`,
  previewVideoContainer: `${PREFIX}-previewVideoContainer`,
  root: `${PREFIX}-root`,
  settingsAndVideoContainer: `${PREFIX}-settingsAndVideoContainer`,
  settingsContainer: `${PREFIX}-settingsContainer`,
  title: `${PREFIX}-title`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    background: theme.palette.common.white,
    borderRadius: 20,
    color: "#364958",
    left: "50%",
    maxWidth: 800,
    overflow: "hidden",
    position: "absolute",
    top: "45%",
    transform: "translate(-50%,-50%)",
    width: "50%",
    zIndex: 20,

    [theme.breakpoints.down(850)]: {
      borderRadius: 0,
      height: "100%",
      left: 0,
      maxHeight: "none",
      maxWidth: "none",
      overflow: "visible",
      top: 0,
      transform: "none",
      width: "100%",
    },
  },

  [`& .${classes.headSection}`]: {
    background: theme.palette.bloom.light,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    paddingBottom: 20,
    paddingTop: 20,
    textAlign: "center",
  },

  [`& .${classes.title}`]: {
    fontFamily: "Inter",
    fontSize: 21,
    fontWeight: "bold",
  },

  [`& .${classes.content}`]: {
    padding: 20,
  },

  [`& .${classes.settingsAndVideoContainer}`]: {
    display: "flex",
    flexDirection: "row",

    [theme.breakpoints.down(850)]: {
      flexDirection: "column",
    },
  },

  [`& .${classes.settingsContainer}`]: {
    // flexShrink: 1,
    flex: 1,
  },

  [`& .${classes.previewVideoContainer}`]: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    padding: "0 20px 20px",
  },

  [`& .${classes.previewVideo}`]: {
    borderRadius: 20,
    maxHeight: 200,
    maxWidth: 400,
    overflow: "hidden",
    [theme.breakpoints.down(850)]: {
      maxHeight: 180,
    },
  },

  [`& .${classes.buttonContainer}`]: {
    display: "flex",
    gap: 20,
    justifyContent: "center",
    padding: 10,
  },

  [`& .${classes.helpContent}`]: {
    display: "flex",
    marginTop: 15,
  },

  [`& .${classes.helpText}`]: {
    "&:hover": {
      cursor: "pointer",
    },
    marginLeft: 5,
    textDecoration: "underline",
  },

  [`& .${classes.joinButton}`]: {
    "&:disabled": {
      opacity: 0.3,
    },
  },

  [`& .${classes.payMsgContainer}`]: {
    padding: 20,
    textAlign: "center",
  },
}));

interface AudioVisualSettingsContainerProps extends IWithChildren {
  cashTransaction?: CashTransaction;
  redirectToPayment?: () => void;
}

export default function AudioVideoSettingsContainer({
  cashTransaction,
  redirectToPayment,
}: AudioVisualSettingsContainerProps) {
  const { t } = useTranslation<I18Namespaces>([BloomUpNamespacesEnum.Ui]);
  const { currentUser } = useContext(AuthContext);

  const {
    startMeeting,
    isJoinedMeeting,
    isWaitingRoomReady,
    startMeetingStatus,
    consultation,
  } = useContext<ICallContext>(CallContext);

  //Reason why we use this method => https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");

    if (newWindow) newWindow.opener = null;
  };

  const getLabel = useCallback(() => {
    if (cashTransaction) {
      const status = cashTransaction.getStatus();

      switch (status) {
        case "new":

        // falls through
        case "open":
          return t("payments:pay.now");

        case "failed":

        // falls through
        case "expired":

        // falls through
        case "canceled":
          return t("payments:payment.retry");

        default:
          return "";
      }
    }
  }, [cashTransaction, t]);

  return (
    <StyledBox className={classes.root}>
      <Box className={classes.headSection}>
        <Typography className={classes.title}>
          {startMeetingStatus === ChimeVideoCall.StartMeetingStatus.loading
            ? t("ui:device.settings.container.loading")
            : t("ui:device.settings.container.welcome")}
        </Typography>
      </Box>
      <Divider />
      <Box className={classes.content}>
        <Box sx={{ fontSize: 16, marginBottom: "32px" }}>
          <Typography variant="h4">
            {t("ui:device.settings.container.info")}
          </Typography>
          <Typography
            sx={{
              alignItems: "flex-end",
              display: "flex",
              marginBottom: "8px",
            }}
          >
            <CalendarMonthIcon
              sx={(theme) => ({
                color: theme.palette.bloom.dark,
                marginRight: "8px",
              })}
            />
            {(consultation?.getStartDate() as Moment).format(
              "dddd D MMMM HH:mm",
            )}
          </Typography>
          <Typography
            sx={{
              alignItems: "flex-end",
              display: "flex",
            }}
          >
            <PersonIcon
              sx={(theme) => ({
                color: theme.palette.bloom.dark,
                marginRight: "8px",
              })}
            />
            {currentUser?.getID() === consultation?.getProfessional().getID()
              ? consultation?.getClient().getFullName()
              : consultation?.getProfessional().getFullName()}
          </Typography>
        </Box>
        <Typography sx={{ marginBottom: "4px" }} variant="h4">
          {t("ui:device.settings.container.settings")}
        </Typography>

        <Box className={classes.settingsAndVideoContainer}>
          <Box className={classes.settingsContainer}>
            <Box sx={{ label: { fontSize: "16px !important;" } }}>
              <VideoInputDeviceSelection />
              <AudioInputDeviceSelection />
            </Box>
            <ActivityBar bloomColor="green" />
            <Box className={classes.helpContent}>
              <Tooltip text={t("ui:device.settings.help.tooltip")}>
                <HelpOutlineIcon />
              </Tooltip>
              <Typography
                className={classes.helpText}
                onClick={() => openInNewTab(t("ui:device.settings.help.link"))}
              >
                {t("ui:device.settings.help")}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.previewVideoContainer}>
            <PreviewVideo className={classes.previewVideo} />
          </Box>
        </Box>
        {cashTransaction && !(cashTransaction.getStatus() === "paid") && (
          <Box className={classes.payMsgContainer}>
            <Typography>{t("ui:device.settings.payFirst")}</Typography>
          </Box>
        )}
        <Box className={classes.buttonContainer}>
          <Button
            bloomColor={isJoinedMeeting ? "green" : "bloom"}
            className={classes.joinButton}
            disabled={
              !isJoinedMeeting ||
              (cashTransaction && !(cashTransaction.getStatus() === "paid")) ||
              differenceInMinutes(
                parseISO(moment(consultation?.getStartDate()).toISOString()),
                new Date(),
              ) > 60
            }
            fullyColored={true}
            icon={<PlayArrowIcon />}
            label={
              isWaitingRoomReady
                ? t("ui:device.settings.container.start.call")
                : t("ui:device.settings.container.patience")
            }
            onClick={startMeeting}
          />
          {redirectToPayment &&
            cashTransaction &&
            !(cashTransaction.getStatus() === "paid") && (
              <Button
                bloomColor="porple"
                icon={<PaymentIcon />}
                label={getLabel()}
                onClick={redirectToPayment}
              />
            )}
        </Box>
      </Box>
    </StyledBox>
  );
}
